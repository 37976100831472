<template>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <div v-if="!commissionRate" class="text-muted font-italic">
        défaut
      </div>
      <div v-else>
        <div :class="{'text-danger': hasCommissionRateExpired}"
             ref="toggleCommissionRateTooltip"
             role="button"
             class="cursor-pointer"
             aria-describedby="tooltip"
             @mouseover="openTooltip"
             @mouseleave="closeTooltip">
        {{ commissionRate.rate }} %
        <span v-if="hasCommissionRateExpired" class="small">(expiré)</span>
      </div>
      <div v-if="isCommissionRateTooltipOpen && commissionRate && (commissionRate.startDate || commissionRate.endDate)"
           class="card"
           ref="tooltip"
           role="tooltip">
        <div ref="tooltip" id="tooltip" role="tooltip" class="card-body small px-3">
          <label>Date(s) du taux de commission</label>
          <table>
            <tbody>
            <tr v-if="commissionRate.startDate">
              <td class="font-weight-bold">
                Date de début
              </td>
              <td>
                <span class="ml-2">{{ $shortDate(commissionRate.startDate) }}</span>
              </td>
            </tr>
            <tr v-if="commissionRate.endDate">
              <td class="font-weight-bold">
                Date de fin
              </td>
              <td>
                <span class="ml-2" :class="{'text-danger': hasCommissionRateExpired}">
                {{ $shortDate(commissionRate.endDate) }}
              </span>
            </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

  <div class="d-inline-flex">
    <fa-icon class="mr-3 text-warning cursor" @click="handleEdit" :icon="['fas', 'pen']"></fa-icon>
    <fa-icon v-if="commissionRate" class="text-danger cursor" @click="handleDelete" :icon="['fas', 'trash']"></fa-icon>
  </div>
  </div>
</template>

<script>
import { isAfter } from 'date-fns'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import formatsDate from '@/mixins/formatsDate'

export default {
  mixins: [formatsDate],
  props: {
    commissionRate: {
      type: Object,
      default: () => {
        return {}
      }
    },
    storeName: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      isCommissionRateTooltipOpen: false
    }
  },
  computed: {
    hasCommissionRateExpired () {
      return this.commissionRate && this.commissionRate.endDate && isAfter(new Date(), new Date(this.commissionRate.endDate))
    }
  },
  methods: {
    async handleEdit () {
      try {
        await this.$modal.openFormModal({
          title: `Édition du taux de commission promotionnel pour la boutique ${this.storeName}`,
          fields: [
            {
              name: 'rate',
              label: 'Taux de commission (%)',
              type: 'number',
              required: true
            },
            {
              name: 'startDate',
              label: 'Date de début',
              type: 'date-picker'
            },
            {
              name: 'endDate',
              label: 'Date de fin',
              type: 'date-picker'
            }
          ],
          initialValues: {
            rate: this.commissionRate ? this.commissionRate.rate : '',
            startDate: this.commissionRate ? this.commissionRate.startDate : null,
            endDate: this.commissionRate ? this.commissionRate.endDate : null
          },
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler',
          onSubmit: async ({ values, confirm, setErrors }) => {
            setErrors({})

            if (!values.rate) {
              setErrors({
                rate: ['Ce champ est requis.']
              })
              return
            }

            if (values.startDate && values.endDate && isAfter(new Date(values.startDate), new Date(values.endDate))) {
              setErrors({
                startDate: ['La date de début ne peut pas être plus grande que la date de fin']
              })
              return
            }

            this.$emit('edit', values)
            confirm()
          }
        })
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        console.error('Error while updating commission rate data', error)
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async handleDelete () {
      try {
        this.hasError = false

        await this.$modal.openConfirmModal({
          title: 'Supprimer le taux de commission promotionnel',
          message: `Voulez-vous supprimer le taux de commission promotionnel de ${this.commissionRate.rate} % pour la boutique ${this.storeName}? Celui-ci sera réinitialisé au taux par défaut. `,
          confirmLabel: 'Confirmer',
          cancelLabel: 'Annuler'
        })

        this.$emit('delete')
      } catch {}
    },
    openTooltip () {
      this.isCommissionRateTooltipOpen = true

      this.$nextTick(() => {
        if (this.$refs.toggleCommissionRateTooltip && this.$refs.tooltip) {
          createPopper(this.$refs.toggleCommissionRateTooltip, this.$refs.tooltip, {
            placement: 'top-start'
          })
        }
      })
    },
    closeTooltip () {
      this.isCommissionRateTooltipOpen = false
    }
  }
}
</script>
