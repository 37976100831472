<template>
  <app-layout>
    <div class="px-5 p-sm-5 container container-center">
      <template v-if="isFetching">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="heading row mb-3">
          <div class="col-12 col-md-6 mb-4">
            <div class="d-flex align-items-center justify-content-start">
              <b-avatar variant="light" size="4rem" :src="storeLogo" :text="storeLogo" />
              <h2 class="ml-3">{{ storeName }}</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'stores.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 mb-4">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Sommaire</h4>
                <dl class="row">
                  <dt class="col-6">Statut d'enregistrement</dt>
                  <dd class="col-6">{{ storeRegistrationStatus }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-6">Slug</dt>
                  <dd class="col-6">
                    <a v-if="store && store.slug" class="text-left" :href="storeAppLink" target="_blank">
                      <u>Visiter</u>
                    </a>
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-6">Administrateur(s)</dt>
                  <dd class="col-6">
                    <router-link v-if="store" :to="{ name: 'users.index', query: { store: store.id } }">
                      <u>Afficher</u>
                    </router-link>
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-6">Code</dt>
                  <dd class="col-6" v-if="store && store.code">{{ store.code }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-6">Dernière mise à jour</dt>
                  <dd class="col-6">{{ storeUpdatedAt }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-6">Taux de commission</dt>
                  <dd class="col-6">
                    <commission-rate-info
                      :commission-rate="commissionRate"
                      :storeName="store.name"
                      @edit="handleUpdateCommissionRate"
                      @delete="handleDeleteCommissionRate"
                    />
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-6">Active</dt>
                  <dd class="col-6">
                    <switch-input
                      :checked="!store.isSuspended"
                      size="lg"
                      :call-back="handleSwitchChange"
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between mb-3 small">
          <h3>Produits</h3>
        </div>

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="paginatedProductsData.data"
              :show-actions="false"
              no-top-border
              hover
              :link="(item) => `/products/${item.id}`"
            ></collection-table>
          </div>

          <div v-if="paginatedProductsData.pageCount > 1" class="drawer-footer d-flex justify-content-center align-items-center">
            <pagination :current-page="paginatedProductsData.currentPage" :pages="paginatedProductsData.pageCount" :range="2" @change="changePage" />
          </div>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import formatsCurrency from '@/mixins/formatsCurrency'
import Image from '@/components/common/Image'
import CommissionRateInfo from '@/components/stores/CommissionRateInfo'
import SwitchInput from '@/components/common/SwitchInput'
import Pagination from '@/components/common/Pagination'
import { getStorageFileSource } from '@/utils/files'
import config from '@/config'
import { getStoreRegistrationStatus } from '@/utils/status'
import http from '@/utils/http'

export default {
  mixins: [formatsDate, formatsCurrency],
  components: {
    CommissionRateInfo,
    Pagination,
    SwitchInput
  },
  data () {
    return {
      isFetching: false,
      isFetchingProducts: false,
      commissionRate: null,
      store: {
        id: '',
        name: '',
        slug: '',
        code: '',
        isSuspended: false,
        publicProfile: undefined,
        updatedAt: undefined
      },
      paginatedProductsData: {
        total: 0,
        currentPage: 1,
        pageCount: 1,
        perPage: 1,
        from: 1,
        to: 1,
        data: []
      }
    }
  },
  computed: {
    storeName () {
      return this.store ? this.store.name : ''
    },
    storeStatus () {
      if (!this.store) {
        return ''
      }

      switch (this.store.status) {
        case 'active':
          return 'Active'
        case 'suspended':
          return 'Suspendue'
        default:
          return ''
      }
    },
    storeRegistrationStatus () {
      return getStoreRegistrationStatus(this.store)
    },
    storeLogo () {
      if (!this.store || !this.store.publicProfile) {
        return ''
      }

      if (!this.store.publicProfile.profilePhoto) {
        const words = this.store.name.split(' ')
        return words.reduce((str, word) => str.concat(word.substr(0, 1)), '')
      }

      return getStorageFileSource(this.store.publicProfile.profilePhoto)
    },
    storeUpdatedAt () {
      if (!this.store || !this.store.updatedAt) {
        return
      }

      return this.$longDate(this.store.updatedAt)
    },
    storeAppLink () {
      if (!this.store) {
        return ''
      }

      return `${config.app.frontUrl}/boutique/${this.store.slug}`
    },
    cols () {
      return [
        {
          header: 'Image Globale',
          property: 'productPhotos',
          component: Image,
          props: (item) => {
            const photo = item.productPhotos?.find(photo => photo.isGlobal)
            return {
              src: photo?.file ? getStorageFileSource(photo.file) : undefined,
              alt: item.name
            }
          }
        },
        {
          header: 'Nom du produit',
          property: 'name'
        },
        {
          header: 'Prix par défaut',
          property: 'defaultPrice',
          method: (product) => {
            return !product.defaultPrice
              ? this.$money(product.defaultRegularPrice)
              : this.$money(product.defaultPrice)
          }
        },
        {
          header: 'Type',
          property: 'type',
          method: (product) => {
            return product.type === 'simple' ? 'Simple' : 'Variantes'
          }
        },
        {
          header: 'Variantes',
          property: 'variants',
          headerClass: 'variants-col-width',
          method: (product) => {
            if (product.type === 'simple') {
              return ''
            }

            return product.variants.length
          }
        }
      ]
    }
  },
  methods: {
    async fetchData () {
      try {
        this.isFetching = true
        const storeId = this.$route.params.id

        const [{ data: store }, { data: paginatedProducts }] = await Promise.all([
          await http.get(`/v1/admin/stores/${storeId}`),
          await http.get(`/v1/stores/${storeId}/products`)
        ])

        this.store = store
        this.paginatedProductsData = paginatedProducts
        try {
          const { data: commissionRate } = await http.get(`/v1/stores/${storeId}/commission-rate`)
          this.commissionRate = commissionRate
        } catch {}
      } catch (e) {
        console.error('Error while fetching store and products data', e)
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      } finally {
        this.isFetching = false
      }
    },
    async handleUpdateCommissionRate (values) {
      try {
        const { data: commissionRate } = await http.put(`/v1/admin/stores/${this.store.id}/commission-rate`, values)
        this.commissionRate = commissionRate
      } catch (error) {
        if (error === 'ACTION_CANCELLED') {
          return
        }

        console.error('Error while updating commission rate data', error)
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    },
    async handleDeleteCommissionRate () {
      try {
        await http.delete(`/v1/admin/stores/${this.store.id}/commission-rate`)
        this.commissionRate = null
      } catch (e) {
        console.error('Error while deleting commission rate data', e)
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      }
    },
    async changePage (page) {
      try {
        this.isFetchingProducts = true
        const params = { page }
        const { data: paginatedProducts } = await http.get(`/v1/stores/${this.$route.params.id}/products`, { params })
        this.paginatedProductsData = paginatedProducts
      } catch (e) {
        console.error('Error while fetching store and products data', e)
        this.$toasted.error("Une erreur interne s'est produite. Réessayez dans quelques instants.")
      } finally {
        this.isFetchingProducts = false
      }
    },
    async handleSwitchChange (isActive) {
      try {
        const reason = await this.$modal.open(
          () => import('@/modals/SuspendModal.vue'),
          { isSuspended: !isActive, storeName: this.store.name, storeId: this.store.id }
        )

        // edit store isSuspended
        this.store = await this.$store.dispatch(
          'stores/editStatus',
          { storeId: this.store.id, isSuspended: !isActive, reason }
        )
        const successMessage = !isActive
          ? `Boutique ${this.store.name} suspendue avec succès.`
          : `Boutique ${this.store.name} activée avec succès.`
        this.$toasted.success(successMessage)
      } catch (e) {
        if (e === 'ACTION_CANCELLED') {
          return
        }

        this.$toasted.error(e?.response?.data)
      }
    }
  },
  async created () {
    await this.fetchData()
  }
}
</script>
